import { useEffect, useState, useContext, useRef, useMemo } from 'react';
import React from 'react'
import { useTranslation } from '../../../contexts/TranslationProvider'
import EditContractTemplateContext from '../../../contexts/EditContractTemplateContext';
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { NumberParam, SegmentedClauseParam } from '../../../domain/types/ClauseParams';
import { getAllParams } from '../../../domain/ContractTemplate';
import { validateFormula} from '../../../domain/Form';

import ConstraintRender from '../../FormTemplatePage/components/FormTemplateParameterInput/ConstraintRender';

function EditParamBasicInfo({ param, clauseId, subClauseId }: { param: SegmentedClauseParam, clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], setUnvalidFormula?: (value: boolean) => void }) {
  const { t, language } = useTranslation();
  const errorTranslationPath = t("pages.contractTemplateParam.addParamForm.errors");
  const translationPath = t("pages.contractTemplateParam.edition")
  const [errors, setErrors] = useState(null);
  const [paramName, setParamName] = useState(param?.name);
  const latestParamNameRef = useRef(paramName);
  const errorsRef = useRef(errors);
  const [formulaInput, setFormulaInput] = useState((param.type == 'number' || param.type == 'string') && param.formula);
  const [showDropDown, setShowDropDown] = useState(false);
  const [filteredParams, setFilteredParams] = useState([])
  const [filteredFormulas, setFilteredFormulas] = useState([])
  const [selectedFormat, setSelectedFormat] = useState(param.format);

  const updateName = () => {
    onParamNameChanged(param, latestParamNameRef.current);
  }
  useEffect(() => {
    if (param.type == 'string' || param.type == 'number') {
      const filteredParams = otherParams.filter(p => p.name.toLowerCase().includes(formulaInput?.toLowerCase()));
      const filteredFormulas = predefinedFormulas.filter(f => f.toLowerCase().includes(formulaInput?.toLowerCase()));
      setFilteredParams(filteredParams)
      setFilteredFormulas(filteredFormulas)
    }
  }, [])
  useEffect(() => {
    return () => {
      if (!errorsRef.current) {
        updateName();
      } else {
        console.log("Errors found, not updating name.", errorsRef.current);
      }
    };
  }, []);
  const predefinedNumberFormats = [
    "formatDecimal",
    "formatPercentage",
    "formatThousands",
    "formatCurrencyUSD",
    "formatCurrencyEUR",
    "formatCurrencyTND",
    "formatScientific",
  ];

  const predefinedDateFormats = [
    "fmtShortMMDDYYYY",
    "fmtShortDDMMYYYY",
    "fmtISO",
    "fmtLongMonthDayYear",
    "fmtLongDayMonthYear",
    "fmtWeekdayMonthDayYear",
    "fmtWithTime24",
    "fmtWithTimeFull24",
    "fmtWithTimeHMSS",
    "fmtWithTime12",
    "fmtWithTimeFull12",
    "fmtMonthDayYear12",
    "fmtWeekdayShort",
    "fmtWeekdayLong",
    "fmtWeekdayMonthDay",
    "fmtISOExtended",
    "fmtRFC"
  ];
  const [formatOptions, setFormatOptions] = useState(predefinedNumberFormats);
  useEffect(() => {
    if (param.type === 'number') {
      setFormatOptions(predefinedNumberFormats);
    } else if (param.type === 'date') {
      setFormatOptions(predefinedDateFormats);
    }
  }, [param.type]);
  const handleFormatChange = (event) => {
    const format = event.target.value;
    const formatted = `${format}(${paramName})`;
    setSelectedFormat(formatted);
    onParamChanged(clauseId, subClauseId, { ...param, format: formatted });
  };
  useEffect(() => {
    if (selectedFormat) {
      setSelectedFormat(prevFormat => {
        const newFormat = prevFormat.replace(/\(([^)]+)\)/, `(${paramName})`);
        onParamChanged(clauseId, subClauseId, { ...param, format: newFormat });
        return newFormat;
      });
    }
  }, [paramName]);
  const {
    onParamNameChanged,
    onParamChanged,
    contractTemplate,
    combinedTemplateParams
  } = useContext(EditContractTemplateContext);

  const params = getAllParams(contractTemplate)

  const otherParams = useMemo(() =>
    getAllParams(contractTemplate)
      .filter(p => p.type == 'number'
        || p.type == 'boolean'
        || p.type == 'enum'
        || p.type == 'date'
        || p.type == 'string'
      )
      .filter(p => p.name != param.name)
    , [contractTemplate])

  const unvalidFormula = useMemo(() =>
    (param.type === 'number' || param.type === 'string') && param.formula && !validateFormula(param.formula, otherParams)
    , [(param as any).formula, otherParams])
  const predefinedFormulas = ['LCM', 'now', 'dateDiffInYears', 'calAge', 'numberToLetters', "numberToLettersCAPS"];  
  const formulaOperators = ['+', '-', '*', '/', ',', '(', ')', ':', '?'];
  const handleFormulaChange = (value: string) => {
    setFormulaInput(value);
    onParamChanged(clauseId, subClauseId, {
      ...param,
      formula: value
    })
    const lastPart = (value ?? '').split(new RegExp(`[${formulaOperators.map(op => '\\' + op).join('')}]`)).pop()?.trim();
    if (lastPart) {
      setFilteredParams(otherParams.filter(p => p.name.toLowerCase().includes(lastPart.toLowerCase())));
      setFilteredFormulas(predefinedFormulas.filter(f => f.toLowerCase().includes(lastPart.toLowerCase())));
    }

    setShowDropDown(lastPart && lastPart.length > 0);
  };
  const onchange = (value) => {
    setErrors(null);
    errorsRef.current = null;
    setParamName(value);
    setSelectedFormat(selectedFormat)
    latestParamNameRef.current = value;
    if (!value) {
      setErrors(t(errorTranslationPath.nameMissing));
      errorsRef.current = t(errorTranslationPath.nameMissing);
    }
    if (!/^[a-z_][a-z0-9_]*$/i.test(value)) {
      setErrors(t(errorTranslationPath.nameWrong));
      errorsRef.current = t(errorTranslationPath.nameWrong);
    }
    const existingParam = combinedTemplateParams.find(param => param.name === value);
    if (!existingParam) {
      return;
    }
    if (existingParam && (param.name !== value)) {
      setErrors(t(errorTranslationPath.nameExists));
      errorsRef.current = t(errorTranslationPath.nameExists);
    }
  }
  const replaceLastPart = (selectedValue: string) => {
    const regex = new RegExp(`([^${formulaOperators.join('\\')}]+)$`);
    const newFormula = formulaInput.replace(regex, selectedValue);
    handleFormulaChange(newFormula);
    setShowDropDown(false);
  };
  const onChangeConstraint = (constraints: NumberParam['constraints']) => {
    onParamChanged(clauseId, subClauseId, {
      ...param,
      constraints
    } as NumberParam)
  }

  return (
    <div>
    
      {/* Edit param name */}
      <div className="edit-param-container">
        <div className="edit-param-name">
          <label className="label">{t(translationPath.parameterName)}</label>
          <input
            className="input"
            value={paramName}
            type="text"
            onChange={(e) => {
              onchange(e.target.value);
            }}
          />
        </div>
        <div className="error">
          {errors}
        </div>
      </div>
      {/* Edit parameter label */}
      <div className="edit-param-container">
        <div className="edit-param-name">
          <label className="label">{t(translationPath.parameterLabel)}</label>
          <input
            className="input"
            value={param?.label}
            type="text"
            onChange={(e) => {
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }}
            onBlur={(e) => {
              onParamChanged(clauseId, subClauseId, {
                ...param,
                label: e.target.value,
              })
            }}
          />
        </div>
      </div>
      {(param.type == 'number' || param.type == 'date' )&&
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
          <label style={{minWidth:"80px"}}  className="label px-2">{t(translationPath.parameterFormat)} </label>
          <div style={{position:"relative", width:"100%"}}>
          <select
          style={{width:"100%"}}
            className="custom-form-select"
            value={selectedFormat|| ""}
            onChange={handleFormatChange}
          >
            <option value={selectedFormat} disabled>{selectedFormat}</option>
            {formatOptions?.map((format, idx) => (
              <option key={idx} value={format}>{format}</option>
            ))}
          </select>
        </div>
        </div>
      }
      {(param.type === 'number' || param.type === 'string') &&
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
          <label  style={{minWidth:"80px"}} className="label px-2">{t(translationPath.parameterFormula)} </label>
          <div style={{ position: "relative" ,width:"100%"}}>
            <textarea
              className="custom-form-textarea formula mt-2 px-2"
              value={formulaInput ? formulaInput : param.formula ?? ""}
              onChange={(e) => handleFormulaChange(e.target.value)}
            />
            {unvalidFormula && (
              <div className="error-message-dialog">
                {translationPath.unvalidFormula}
              </div>
            )}

            {showDropDown && (filteredParams.length > 0 || filteredFormulas.length > 0) && (
              <ul className="dropdown-list-formula">
                {filteredParams.map((p, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(p.name); setShowDropDown(false); }}>
                    {p.name}
                  </li>
                ))}
                {filteredFormulas.map((f, idx) => (
                  <li key={idx} onClick={() => { replaceLastPart(f); setShowDropDown(false); }}>
                    {f}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      }
      {(param.type == 'number' || param.type == 'string' || param.type == 'date') && <div>
        <ConstraintRender
          isEditing={true}
          param={param as NumberParam}
          constraints={(param as NumberParam).constraints}
          onChangeConstraint={onChangeConstraint}
          params={params} />
      </div>}
   

    </div>
  )
}

export default EditParamBasicInfo