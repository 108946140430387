import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormListInputItem } from '../../../Form/components/FormListInputItem';
const { default: Delete } = require("../../../../assets/delete.svg");

function ListRender({ isEditing, value, valueName, onChange, param, page, onChangeName, errors, onDescriptionChange }) {
  const { onOptionChanged, onOptionDelete, onOptionAdd } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div className="wrapper-item-list-form">
        <div>{!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label" htmlFor={param.name}>
            {translationPath.parameterLabel} :  {param.label}
          </label>
        ) : (
          <textarea
            className="custom-form-textarea"
            style={{ marginLeft: "15px" }}
            name={param.name}
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
        </div>
        <div>
          {!isEditing ? (
            <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
          ) : (
            <textarea
              className="custom-form-textarea"
              value={param.description ?? ""}
              onChange={(e) => onDescriptionChange(e)}
              placeholder={translationPath.parameterDescription}
            />
          )}
        </div>
        <div className="form-template-list-params">
          {param?.args &&
            param?.args?.map((arg, index) => {
              return !isEditing ? (
                <FormListInputItem
                  key={index}
                  index={index}
                  currentValue={[]}
                  handleInputChange={() => { }}
                  param={param}
                  colorLabel="#212529"
                />
              ) : (
                <div className="d-flex w-100 param-list-container">
                  <div className="d-flex flex-column w-100 mb-2">
                    <textarea
                      className="custom-form-textarea"
                      style={{ height: "50px" }}
                      value={arg.option}
                      onChange={(e) =>
                        onOptionChanged(
                          page.id,
                          param,
                          { ...param.args[index], option: e.target.value },
                          index
                        )
                      }
                    />
                    <div
                      style={{ marginTop: "10px", gap: "5px" }}
                      className="d-flex"
                    >
                      <input
                        type="checkbox"
                        checked={arg.required}
                        onChange={(e) =>
                          onOptionChanged(
                            page.id,
                            param,
                            {
                              ...param.args[index],
                              required: e.target.checked,
                            },
                            index
                          )
                        }
                      />
                      <label>{t(translationPath.list.required)} </label>
                    </div>
                  </div>
                  <img
                    src={Delete}
                    className="delete-single-option"
                    onClick={() => onOptionDelete(page.id, param, index)}
                  />
                </div>
              );
            })}
          {isEditing && (
            <p
              className="add-single-option"
              // style={{ marginLeft: "0px" }}
              onClick={() => {
                onOptionAdd(page.id, param, {
                  option: "",
                  required: false,
                });
              }}
            >
              +
            </p>
          )}
        </div>
      </div>
    </div>

  )
}

export default ListRender