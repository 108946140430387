import React, {useState, useContext, useEffect} from 'react'
import './regenerateClause.scss'
import { ClauseParams, SegmentedClauseParams } from '../../../domain/types/ClauseParams'
import AddParamForm from './AddParamForm'
import EditContractTemplateContext from '../../../contexts/EditContractTemplateContext'
import { getAllOriginalParams } from '../../../domain/ContractTemplate'
import ParamDisplay from './ParamDisplay'
import ragClientWithLoading from '../../../services/rag/RagClient'
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { ContractTemplateClient } from '../../../services/api/ContractTemplateClient'
import { ReglementClient } from '../../../services/rag/ReglementClient'
import PromptInput from './PromptInput'
import { ClauseEntity, ReglementEntity } from '../../../domain/entities'
import { toast } from 'react-toastify'
import { FaPlus } from "react-icons/fa";
import { useTranslation } from '../../../contexts/TranslationProvider'
import { REACT_APP_DEV_MODE } from '../../../config'
function RegenerateClause({clause, handleResolveRegenerate, generationLoading, setGenerationLoading}: {
  clause: ClauseEntity,
  handleResolveRegenerate: (clause: ClauseEntity) => void,
  generationLoading: boolean,
  setGenerationLoading: (loading: boolean) => void
}) {
  const [additionalParams, setAdditionalParams] = useState<ClauseParams>([])
  const [formOpen, setFormOpen] = useState(false)
  const [useRegulatoryAssistant, setUseRegulatoryAssistant] = useState(false)
  const [instructions, setInstructions] = useState('')
  const {contractTemplate} = useContext(EditContractTemplateContext)
  const originalParams = getAllOriginalParams(contractTemplate)
  const [selectedRegulation, setSelectedRegulation] = useState(null)
  const ragClient = ragClientWithLoading()
  const reglementClient = new ReglementClient(ragClient)
  const apiClient = useApiClientWithLoading()
  const translationPath = "modals.clauseExchange.";
  const { t } = useTranslation()
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const [generationType, setGenerationType] = useState<'simple' | 'advanced'>('simple')
  const [regulations, setRegulations] = useState<ReglementEntity[]>([])
  const fetchRegulations = async () => {
    try{
      const allRegulations = await reglementClient.getAll()
      setRegulations(allRegulations.rows?.filter(reg => reg.has_vectorstore))
    }catch(err){
      console.log(err)
    }
  }
  useEffect(() => {
    fetchRegulations()
  }, [])
  const handleRemove = (param) => {
    setAdditionalParams(additionalParams.filter(p => p.name !== param.name))
  }
  const handleToggleType = () => {
    setGenerationType((prev)=> {
      if(prev === "advanced")
        return "simple"
      if(prev === "simple")
        return "advanced"
      })
  }
  const handleSubmit = async () => {
    setGenerationLoading(true)
    let regulationName = null
    let regulationId = null
    let regulationContext = null
    if(useRegulatoryAssistant && selectedRegulation){
      const selectedRegulationObj = regulations.find(regulation => regulation.id === selectedRegulation)
      regulationName = selectedRegulationObj.name
      regulationId = selectedRegulationObj.id
      try{
        const ragQuery = `Contract: ${contractTemplate.name} Clause: ${clause.name} ${instructions}`
        console.log("ragQuery", ragQuery)
        const relevantInfo = await reglementClient.getRelevantInfo(ragQuery, selectedRegulationObj.id)
        regulationContext = relevantInfo.relevant_docs ? relevantInfo.relevant_docs : null
      }catch(err){
        toast.error('regulatory assistant failed, continuing without regulatory assistant')
        console.log(err)
        regulationContext = null
      }
    }
    try{
      const response = await contractTemplateClient.regenerateClauseWithAi(clause.id, instructions, additionalParams, regulationName, regulationContext)
      const generatedClause = (response as any).clauseData;
      handleResolveRegenerate({...generatedClause, name: clause.name, id: clause.id})
      setGenerationLoading(false)
    }catch{
      setGenerationLoading(false)
      toast.error('Failed to regenerate clause')
    }
  }
  return (
    <div className='regenerate-container'>
      <div className='content-section'>
      {generationType === 'advanced' && <>
      <div className='regenerate-section'>
        <span className='section-title'>
          {t(translationPath + 'params')}
        </span>
        <div className='params-container'>
          {additionalParams.map((param, index) => (
            <ParamDisplay key={index} param={param} handleRemove={handleRemove}/>
          ))}
          {!formOpen && <span className='add-param' onClick={() => setFormOpen(true)}>
            <FaPlus style={{fontSize: "18px"}}/>
            </span>}
          {formOpen && <div className='add-param-form-container'>
            <AddParamForm params={[...originalParams, ...additionalParams]} handleAdd={(param) => setAdditionalParams([...additionalParams, param])} handleCancel={() => setFormOpen(false)} />
            </div>}
        </div>
      </div>
      {REACT_APP_DEV_MODE && <div className='regenerate-section'>
        <div className='section-title'>
          {t(translationPath + 'regulatoryAssistant')}
          <input type='checkbox' checked={useRegulatoryAssistant} onChange={() => setUseRegulatoryAssistant(!useRegulatoryAssistant)}/>
        </div>
        {
          useRegulatoryAssistant && (<>
          <div className='regulation-list'>
            {
              regulations.map((regulation) => (
                <div 
                key={regulation.id} 
                className={`regulation ${selectedRegulation === regulation.id ? 'selected-regulation' : ''}`} 
                onClick={() => setSelectedRegulation(regulation.id)}>
                  <span>{regulation.name}</span>
                </div>
              ))
            }
          </div>
          </>)
        }
      </div>}
      </>}
      </div>
      <div className='prompt-section'>
        <PromptInput 
        handleSubmit={() => handleSubmit()} 
        loading={generationLoading} 
        triggerSpecialAction={handleToggleType} 
        query={instructions}
        handleQueryChange={(query) => setInstructions(query)}
        specialActionOn={generationType === 'advanced'}
        />
      </div>
        
    </div>
  )
}

export default RegenerateClause