// src/utils/parseContractData.js

import { ClauseEntity, ContractEntity, ContractAIEntity, ContractTemplateEntity, SubClauseEntity, FormTemplateEntity } from "../domain/entities";
import { SegmentedClauseParam, SegmentedClauseParams } from "../domain/types/ClauseParams";
import { numberToLetters } from "../utils/math";
import {
  isClauseExcluded,
  isSubClauseExcluded,
} from "./helper";

export const getTemplateClause = (templateData, clauseId) => {
  return templateData
    .find(clause => clause.id == clauseId)
}

export function getClauseName(contractTemplate: ContractTemplateEntity, clause: ClauseEntity, clauseIndex: number) {
  const orgClauseIndex = Number(clause.index)
  const clauseSetting = contractTemplate.clauseSettings?.find(cs => cs)
  // .find(cs => cs.range[0] <= orgClauseIndex && orgClauseIndex <= cs.range[1])
  if (clauseSetting) {

    return clauseSetting.clauseFormula
      .replace("$clauseName", clause.name)
      .replace("$clauseIndexLetter", String(numberToLetters(clauseIndex + 1)))
      .replace("$clauseIndex", String(clauseIndex + 1))
  }
  return clause.name
}

export function getSubClauseName(contractTemplate: ContractTemplateEntity, clause: ClauseEntity, subClause: ClauseEntity, clauseIndex: number, subClauseIndex: number) {
  const orgIndex = Number(clause.index)
  const clauseSetting = contractTemplate.clauseSettings?.find(cs => cs)
  // .find(cs => cs.range[0] <= orgIndex && orgIndex <= cs.range[1])
  if (clauseSetting) {
    return clauseSetting.subClauseFormula
      .replace("$clauseName", clause.name)
      .replace("$clauseIndexLetter", String(numberToLetters(clauseIndex + 1)))
      .replace("$clauseIndex", String(clauseIndex + 1))
      .replace("$subClauseName", subClause.name)
      .replace("$subClauseIndexLetter", String(numberToLetters(subClauseIndex + 1)))
      .replace("$subClauseIndex", String(subClauseIndex + 1))
  }
  return subClause.name
}

export const getTemplateClausesSummaryData = (
  templateData: ContractTemplateParsedData,
  excludedClauses: ContractEntity['excludedClauses'],
  excludedSubClauses: ContractEntity['excludedSubClauses'],
) => {
  const summary = templateData
    .filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
    .map((clause, idx) => {
      return {
        name: clause.name,
        subClauses: clause.subClauses
          .filter(
            (subClause) =>
              !isSubClauseExcluded(subClause.id, excludedSubClauses)
          )
          .map((subClause, idx) => subClause.name),
      };
    });
  return summary;
};
const parseTemplateData = (row: ContractTemplateEntity, additionalClauses: ContractEntity['additionalClauses'] = []) => {
  if (!row?.clauses)
    return []
  const clauses = row.clauses
    .map(clause => additionalClauses.find(ac => ac.Clause_Contract.templateClauseReplacedId == clause.id) || clause)
    .map((clause, index) => {
      if ((clause as any).Clause_Contract) {
        console.warn("additionalClause", clause);
        console.warn(row.clauses[index]);
      }
      const subClauses = clause.subClauses.map((subClause) => {
        return {
          id: subClause.id,
          name: subClause.name,
          code: subClause.code,
          index: subClause.index,
          isOptional: subClause.isOptional,
          segmentation: subClause.segmentation,
          comment: subClause.comment,
        };
      });
      return {
        id: clause.id,
        name: clause.name,
        index: clause.index,
        code: clause.code,
        subClauses: subClauses,
        segmentation: clause.segmentation,
        comment: clause.comment,
        isOptional: clause.isOptional,
      };
    });

  clauses.sort((a, b) => {
    const [aMajor, aMinor] = a.code.split("-").map(Number);
    const [bMajor, bMinor] = b.code.split("-").map(Number);

    if (aMajor !== bMajor) return aMajor - bMajor; // Compare major number (before the hyphen)
    return aMinor - bMinor; // Compare minor number (after the hyphen)
  });
  return clauses;
};
export type ContractTemplateParsedData = ReturnType<typeof parseTemplateData>
export const extractDataFromAiObject = (aiObject: ContractAIEntity) => {
  const textFileUrl = aiObject.textFile ? aiObject.textFile.url : '';
  const summarySheet = aiObject.summarySheet;
  const contractId = aiObject.id;
  const formId = aiObject.formId;
  const aiResponsesStatus = aiObject?.aiResponsesStatus;

  return {
    textFileUrl,
    summarySheet,
    contractId,
    formId,
    aiResponsesStatus,
    textContent: undefined,
  };
};

export const extractDataFromSummarySheetGroupedByClauseName = (
  summarySheet: ContractAIEntity['summarySheet']
) => {
  // Map over each entry in the summary sheet
  const data = summarySheet.map((item, index) => {
    // Return an object where each property is extracted from the item
    return {
      clauseName: item[0],
      subclauseName: item[1],
      question: item[2],
      response: item[3],
      index: index,
    };
  });

  const groupedData: Record<string, ContractAIEntity['summarySheet']> = data.reduce((acc, item) => {
    const key = item.clauseName;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return groupedData;
};

export function extractOptionalClauses(templateData: ContractTemplateParsedData) {
  const optionalClauses: {
    clauseId: ClauseEntity['id'],
    clauseName: ClauseEntity['name'],
  }[] = [];

  templateData.forEach((clause) => {
    if (clause.isOptional) {
      optionalClauses.push({
        clauseId: clause.id,
        clauseName: clause.name,
      });
    }
  });

  return optionalClauses;
}
export type OptionalClause = {
  clauseId: ClauseEntity['id'],
  clauseName: ClauseEntity['name'],
}

export function extractOptionalSubclauses(templateData: ContractTemplateParsedData) {
  const optionalSubclauses: {
    clauseId: ClauseEntity['id'],
    clauseName: ClauseEntity['name'],
    subClauseId: SubClauseEntity['id'],
    subClauseName: SubClauseEntity['name'],
  }[] = [];
  templateData.forEach((clause) => {
    clause.subClauses.forEach((subClause) => {
      if (subClause.isOptional) {
        optionalSubclauses.push({
          clauseId: clause.id,
          clauseName: clause.name,
          subClauseId: subClause.id,
          subClauseName: subClause.name,
        });
      }
    });
  });

  return optionalSubclauses;
}
export type OptionalSubClause = {
  clauseId: ClauseEntity['id'],
  clauseName: ClauseEntity['name'],
  subClauseId: SubClauseEntity['id'],
  subClauseName: SubClauseEntity['name'],
}


function generateParam({
  param,
  isEditing,
  paramValues,
  clauseId,
  clauseName,
  subClauseId = null,
  subClauseName = null,
}: {
  param: ContractTemplateParsedData[number]["segmentation"]["segmentedParams"][number],
  isEditing: boolean,
  paramValues: ContractEntity['paramValues'],
  clauseId: ClauseEntity['id'],
  clauseName: ClauseEntity['name'],
  subClauseId?: SubClauseEntity['id'],
  subClauseName?: SubClauseEntity['name'],
}) {
  let value = isEditing ? paramValues[param.name] : undefined;

  return {
    ...param,
    rendered: true,
    value,
    subClauseId,
    clauseId,
    clauseName,
    subClauseName,
  };
}
export type ParsedClauseParam = ReturnType<typeof generateParam>

function generateClauseParams(
  clause: ContractTemplateParsedData[number],
  isEditing: boolean,
  paramValues: ContractEntity['paramValues'],
) {
  return (clause.segmentation.segmentedParams || []).map((param) =>
    generateParam({
      param,
      isEditing,
      paramValues,
      clauseId: clause.id,
      clauseName: clause.name,
    })
  );
}

function generateSubClauseParams(
  clause: ContractTemplateParsedData[number],
  isEditing: boolean,
  paramValues: ContractEntity['paramValues'],
) {
  return (clause.subClauses || []).flatMap((subClause) =>
    (subClause.segmentation.segmentedParams || []).map((param) =>
      generateParam({
        param,
        isEditing,
        paramValues,
        clauseId: clause.id,
        clauseName: clause.name,
        subClauseId: subClause.id,
        subClauseName: subClause.name,
      })
    )
  );
}

export function generateCombinedParams(
  templateData: ContractTemplateParsedData,
  isEditing: boolean,
  paramValues: ContractEntity['paramValues']
) {
  if (templateData) {
    return templateData.flatMap((clause) => {
      const clauseParams = generateClauseParams(
        clause,
        isEditing,
        paramValues
      );
      const subClauseParams = generateSubClauseParams(
        clause,
        isEditing,
        paramValues
      );
      return [...clauseParams, ...subClauseParams];
    });
  }
  return [];
}

export type GroupedParsedClauseParam = {
  clauseId: ParsedClauseParam['clauseId'],
  subClauseId: ParsedClauseParam['subClauseId'],
  clauseName: ParsedClauseParam['clauseName'],
  subClauseName: ParsedClauseParam['subClauseName'],
  params: ParsedClauseParam[],
}
export type NestedGroupedParsedClauseParam = {
  clauseId: GroupedParsedClauseParam['clauseId'],
  clauseName: GroupedParsedClauseParam['clauseName'],
  params: GroupedParsedClauseParam['params']
  subclauses: GroupedParsedClauseParam[]
}
export function getClauseNestedGroupParams(clause: ContractTemplateParsedData[number], params: ParsedClauseParam[]) {
  const group: NestedGroupedParsedClauseParam = {
    clauseId: clause.id,
    clauseName: clause.name,
    params: params.filter(param => param.clauseId == clause.id && !param.subClauseId),
    subclauses: clause.subClauses.map(subClause => ({
      clauseId: clause.id,
      clauseName: clause.name,
      subClauseId: subClause.id,
      subClauseName: subClause.name,
      params: params.filter(param => param.subClauseId == subClause.id),
    }))
  }
  return group
}


export const mergeParams = (templateData: ContractTemplateParsedData) => {
  const params: (SegmentedClauseParam & {
    clauseCode: ClauseEntity['code'];
    clauseName: ClauseEntity['name'];
    subClauseCode?: SubClauseEntity['code'];
    subClauseName?: SubClauseEntity['name'];
  })[] = [];
  for (const clause of templateData) {
    for (const param of clause.segmentation.segmentedParams) {
      params.push({
        ...param,
        clauseCode: clause.code,
        clauseName: clause.name,
        subClauseCode: "",
        subClauseName: "",
      })
    }
    for (const subClause of clause.subClauses) {
      for (const param of subClause.segmentation.segmentedParams) {
        params.push({
          ...param,
          clauseCode: clause.code,
          clauseName: clause.name,
          subClauseCode: subClause.code,
          subClauseName: subClause.name,
        })
      }
    }
  }
  return params;
};
export const mergeParamsForm = (template: FormTemplateEntity) => {
  const params = []
  for (const page of template.pages) {
    for (const param of page.params) {
      params.push({
        ...param,
        clauseCode: page.code,
        clauseName: page.name,
      })
    }
  }
  return params;
};

//Will be called to mergeParameter as desired in the api call
export const mergeParamValues = (inputValues: Record<string, any>) => {
  const paramValues: Record<string, any> = {};

  for (const clauseOrSubclause of Object.values(inputValues)) {
    if (
      typeof clauseOrSubclause === "object" &&
      !Array.isArray(clauseOrSubclause)
    ) {
      for (const params of Object.values(clauseOrSubclause)) {
        if (typeof params === "object" && !Array.isArray(params)) {
          Object.entries(params).forEach(([paramName, paramValue]) => {
            paramValues[paramName] = paramValue;
          });
        }
      }
    }
  }

  return paramValues;
};

export default parseTemplateData;
