import React, { useState, useMemo, useContext, useEffect } from 'react'
import { FormParam, FormParams, FormType } from '../../../../domain/types/FormParams';
import EditFormTemplateContext from '../../../../contexts/EditFormTemplateContext';
import { getAllParams } from '../../../../domain/FormTemplate';
import { useTranslation } from '../../../../contexts/TranslationProvider';
import './constraint.scss'
import { validateConstraint } from '../../../../domain/Form';
import { SegmentedClauseParam, SegmentedClauseParams } from '../../../../domain/types/ClauseParams';

const constraintOperators = ['<', '>', '==', '!', '&', '|', '(', ')', ':', '?', '<=', '>=', '!=', '+', '-', '*', '/', '%'];
const predefinedFormulas = ['LCM', 'now', 'dateDiffInYears', 'calAge', 'len', 'equal', 'lessThanDate', 'greaterThanDate', 'equalDate'];
function ConstraintRender({
  constraints,
  isEditing,
  onChangeConstraint,
  param,
  params,
}: {
  constraints: FormType.NumberParam['constraints'];
  isEditing: boolean;
  onChangeConstraint: (constraints: FormType.Constraint[]) => any;
  param: FormType.NumberParam | SegmentedClauseParam;
  params: SegmentedClauseParams | FormParams;
}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const availableParams = useMemo(() => params
    .filter(p => ['number', 'boolean', 'enum', 'date', 'string'].includes(p.type))
    , [params]);
  const [constraintsInput, setConstraintsInput] = useState<FormType.Constraint[]>(constraints ?? []);
  const onSingleConstraintChange = (label: string, value: string, idx: number) => {
    const newConstraints = constraintsInput.map((c, i) => i === idx ? { label, value } : c);
    setConstraintsInput(newConstraints);
    onChangeConstraint(newConstraints);
  }
  return (
    <div className='constraints-container'>
      <span className="custom-form-label">{t(translationPath.parameterConstraints)}: </span>
      {constraintsInput.map((constraint, idx) => (
        <div className='constraint-container' key={idx}>
          {isEditing ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <SingleConstraintInput constraint={constraintsInput[idx]} availableParams={availableParams} onSingleConstraintChange={onSingleConstraintChange} index={idx}/>
              <div key={idx} className='btn-symbol' style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", marginLeft: "10px" }} 
              onClick={() => {
                const newConstraints = [...constraintsInput].filter((_, i) => i !== idx);
                setConstraintsInput(newConstraints);
                onChangeConstraint(newConstraints);
              }}>
                -
              </div>
            </div>
          ) : (
            <div style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">
              {translationPath.parameterConstraint} {idx + 1} : {constraint.label ?? ""}
            </div>
          )}
        </div>
      ))
    }
      {isEditing && (
        <div className='btn-symbol' style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} 
        onClick={() => {
          //setConstraintsInput([...constraintsInput, { label: "", value: "" }])
          const newConstraints = [...constraintsInput, { label: "", value: "" }];
          setConstraintsInput(newConstraints);
          onChangeConstraint(newConstraints);
        }}>
          +
        </div>
      )}
    </div>
  )
}

const SingleConstraintInput = ({
  constraint,
  availableParams,
  onSingleConstraintChange,
  index
}: {
  constraint: FormType.Constraint;
  availableParams: (FormParam | SegmentedClauseParam) [];
  onSingleConstraintChange: (label: string, value: string, idx: number) => void;
  index: number;
}) => {
  const { t } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const [constraintInput, setConstraintInput] = useState(constraint.value);
  const [constraintLabel, setConstraintLabel] = useState(constraint.label);
  const [showConstraintDropDown, setShowConstraintDropDown] = useState(false);
  const [filteredConstraintParams, setFilteredConstraintParams] = useState<(FormParam | SegmentedClauseParam)[]>([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);
  useEffect(() => {
    onSingleConstraintChange(constraintLabel, constraintInput, index);
  }, [constraintInput, constraintLabel]);
  useEffect(() => {
    const filteredConstraintParams = availableParams.filter(p => p.name.toLowerCase().includes(constraintInput?.toLowerCase()));
    const filteredFormulas = predefinedFormulas.filter(f => f.toLowerCase().includes(constraintInput?.toLowerCase()));
    setFilteredConstraintParams(filteredConstraintParams);
    setFilteredFormulas(filteredFormulas);
  }, []);
  const replaceLastPart = (selectedValue: string, operators: string[], input: string, onClose: ()=> void) => {
    const regex = new RegExp(`([^${operators.join('\\')}]+)$`);
    const newFormula = input.replace(regex, selectedValue); 
    setConstraintInput(newFormula);  
    onClose();
  };
  const unvalidConstraint = useMemo(() => constraintInput && !validateConstraint(constraintInput, availableParams), [constraintInput, availableParams]);
  const handleConstraintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setConstraintInput(value);
  
    const lastPart = value.split(new RegExp(`[${constraintOperators.map(op => '\\' + op).join('')}]`)).pop()?.trim();
    if (lastPart) {
      setFilteredConstraintParams(availableParams.filter(p => p.name.toLowerCase().includes(lastPart.toLowerCase())));
      setFilteredFormulas(predefinedFormulas.filter(f => f.toLowerCase().includes(lastPart.toLowerCase())));
    }
  
    setShowConstraintDropDown(lastPart && lastPart.length > 0);
  };
  return (
    <div className='inputs' style={{ display: "flex", flexDirection: "row" }}>
      <div >
        <textarea
          className="custom-form-textarea label"
          value={constraintLabel}
          onChange={(e) => setConstraintLabel(e.target.value)}
          style={{ resize: "none" }}
        />
      </div>
      <div style={{ position: "relative", flex: 1 }}>
        <textarea
          className="custom-form-textarea formula"
          value={constraintInput}
          onChange={(e) => handleConstraintChange(e)}
        />
        {showConstraintDropDown && (filteredConstraintParams.length > 0 || filteredFormulas.length > 0) && (
          <ul className="dropdown-list-formula">
            {filteredConstraintParams.map((p, idx) => (
              <li key={idx} onClick={() => { replaceLastPart(p.name, constraintOperators, constraintInput, () => setShowConstraintDropDown(false)); setShowConstraintDropDown(false); }}>
                {p.name}
              </li>
            ))}
            {filteredFormulas.map((f, idx) => (
              <li key={idx} onClick={() => { replaceLastPart(f, constraintOperators, constraintInput, () => setShowConstraintDropDown(false)); setShowConstraintDropDown(false); }}>
                {f}
              </li>
            ))}
          </ul>
        )}
        {unvalidConstraint && <div className="error-message-dialog">{translationPath.unvalidConstraint}</div>} 
      </div>
    </div>
  )
}

export default ConstraintRender