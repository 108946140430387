import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";

function BooleanRender({ param, page, isEditing, value, valueName, onChange, onChangeName, errors, onDescriptionChange }) {
  const { onValueChange, paramValues } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label" htmlFor={param.name}>
            {translationPath.parameterLabel} : {param.label}
          </label>
        ) : (
          <textarea
            className="custom-form-textarea"
            name={param.name}
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
        <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
        <div className="radio-group boolean-group">
          <label className="form-check-label">
            <input
              type="radio"
              value={"true"}
              checked={paramValues[param.name] !== undefined && paramValues[param.name] === (true as any)}
              className="form-check-input boolean-check"
              onChange={() => {
                onValueChange(page.id, param, true)
              }}
              name={param.name}
            />
            {t(translationPath.boolean.oui)}
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              value="false"
              checked={paramValues[param.name] !== undefined && paramValues[param.name] === (false as any)}
              className="form-check-input boolean-check"
              onChange={() => {
                onValueChange(page.id, param, false)
              }}
              name={param.name}
            />
            {t(translationPath.boolean.non)}
          </label>
        </div>
      </div>
    </div>

  )
}

export default BooleanRender