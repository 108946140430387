import React, { useEffect } from "react";
import { SegmentedTextType } from "../../domain/types/ClauseParams";
import { renderStyledSegment } from "../../helpers/renderSegment";
import TableRender from "./TableRender";

interface RenderSingleSegmentProps {
  segment: any; // Replace with the correct type for your segment
  isSelected: boolean;
  isEditing: boolean;
  editingSegmentId: string | null;
  handleStaticContentChange: (id: string) => (e: React.FocusEvent<HTMLSpanElement>) => void;
  openFilePreview: (id: string) => void;
  changedParamBoundaries: [number, number][];
  stateSync: any;
  spanRef: any;
  segmentIdx: number;
  segmentation: any;
  inputValues: Record<string, any>;
  segmentsOverrides: Record<string, string>;
}

const RenderSingleSegment: React.FC<RenderSingleSegmentProps> = ({
  segment,
  isSelected,
  isEditing,
  editingSegmentId,
  handleStaticContentChange,
  openFilePreview,
  changedParamBoundaries,
  stateSync,
  spanRef,
  segmentIdx,
  segmentation,
  inputValues,
  segmentsOverrides
}) => {
  const { id, value, type } = segment;
  if (segmentsOverrides[id]) {
    segment.value = segmentsOverrides[id]
  }
  const text = value;
  const isHighlighted = changedParamBoundaries.some(
    (b) => b[0] < segmentIdx && segmentIdx < b[1]
  );
  const styledSegment = renderStyledSegment(segment);

  const editableProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > = (isEditing && {
    contentEditable: true,
    suppressContentEditableWarning: true,
    onClick: (e) => stateSync.set(id),
  }) ||
    {};
  const editingProps: React.DetailedHTMLProps<
    React.HTMLAttributes<any>,
    any
  > = (isEditing && {
    ref: spanRef,
    contentEditable: true,
    suppressContentEditableWarning: true,
    onBlur: handleStaticContentChange(id),
  }) ||
    {};

  const isSegmentEditing = isEditing && editingSegmentId === id;

  switch (type) {
    case SegmentedTextType.PARAM_START:
    case SegmentedTextType.PARAM_END:
      return null; // Skip rendering

    case SegmentedTextType.STATIC:
      return (
        <span
          onClick={() => console.log(editingSegmentId)}
          key={id}
          {...(isSegmentEditing ? editingProps : editableProps)}
          className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
            } preserve-spaces`}
        >
          {styledSegment}
        </span>
      );

    case SegmentedTextType.COMMENT:
      return (
        <span
          key={id}
          className={`comment ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
          dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br/>") }}
        />
      );

    case SegmentedTextType.PARAM:
      const paramPath = value.split(".");
      const param = segmentation.segmentedParams.find((param) => param.name === segment.paramName);
      const paramLabel = param?.label;
      const paramTextVal = `${paramLabel ?? value} ${(param.type === 'beneficial' || param.type === 'beneficial[]') && paramPath[1] ? `(${paramPath[1]})` : ''}`;
      const styledParamVal = renderStyledSegment({ ...segment, value: paramTextVal, style: segment.style });
      return (
        <span
          key={id}
          className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""
            } preserve-spaces`}
        >
          {styledParamVal}
        </span>
      );
    case SegmentedTextType.PARAM_VALUE:
      const paramX = segmentation.segmentedParams.find(
        (param) => param.name == segment.paramName
      );
      const styledParamValue = renderStyledSegment({
        ...segment,
        value: text,
        style: segment.style,
      });
      if (paramX && paramX?.type === "file") {
        return (
          <React.Fragment>
            <span
              onClick={() => {
                openFilePreview(inputValues[paramX.name]);
              }}
              style={{ whiteSpace: "pre-line", cursor: "pointer" }}
              className={`param-value ${isSelected ? "selected-text" : ""
                } ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
            >
              <button onClick={()=>console.log(segment)}>segmennt</button>
              {styledParamValue}
            </span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <span
              style={{ whiteSpace: "pre-line" }}
              className={`param-value ${isSelected ? "selected-text" : ""
                } ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}
            >
              {styledParamValue}
            </span>
          </React.Fragment>
        );
      }

    case SegmentedTextType.PARAM_TABLE_VALUE:
      const [transposed, tableRows] = JSON.parse(value);
      const [headers, ...rows] = tableRows as string[][];
      return (
        <TableRender
          key={id}
          transposed={transposed}
          headers={headers}
          rows={rows}
          isHighlighted={isHighlighted}
        />
      );

    // Handle other types as needed...

    default:
      return null; // Skip unknown segment types
  }
};

export default RenderSingleSegment;
