import React from 'react'
import { useTranslation } from '../../../../contexts/TranslationProvider'

function FileRender({param, isEditing, value, onChange,valueName,onChangeName, errors, onDescriptionChange}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes= "pages.contractTemplateParam.params"


  return (
    <div className="input-param">
    <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
    <div>
      {!isEditing ? (
        <label style={{justifyContent: language === "ar" ? "right" : "left"}} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
      ) : (
        <>
        <textarea
          className="custom-form-textarea"
          value={valueName}
          onChange={onChangeName}
          placeholder={translationPath.parameterName}
        />
        {
          errors.map((err, idx) => {
            if (err.where === "name") {
              return <div key={idx} className="error-message-dialog">{err.why}</div>
            }
          })
        }
        </>
      )}
    </div>
    {!isEditing ? (
      <label style={{justifyContent: language === "ar" ? "right" : "left"}} htmlFor={param.name} className="custom-form-label">
        {translationPath.parameterLabel} :{param.label}
      </label>
    ) : (
      <textarea
        className="label-input-edit mt-3"
        style={{ marginLeft: "8px", marginBottom: "10px" }}
        name={param.name}
        value={value}
        onChange={onChange}
        placeholder={translationPath.parameterLabel}
      />
    )}
    <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
  </div>
  )
}

export default FileRender