import React, { useContext, useMemo, useState } from 'react'
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import CustomCombobox from '../../../../components/common/CustomCombobox';
import CustomPropertiesContext from '../../../../contexts/CustomPropertiesContext';
import { getAllParams } from '../../../../domain/FormTemplate';
import ParamCombobox from '../../../ContractTemplate/components/ParamCombobox';
function PropertyRender({ isEditing, value, valueName, onChange, param, page, onChangeName, errors, onDescriptionChange, pageId, onParamTypeChange }) {
  const { onParamChanged, formTemplate } = React.useContext(EditFormTemplateContext);
  const { t, language } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  const [isParentOpen, setIsParentOpen] = useState(false)
  const { tags, tagMapping } = useContext(CustomPropertiesContext)

  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  const parentTagParams = useMemo(() => {
    const params = getAllParams(formTemplate)
    if (param.args.tag && tagMapping[param.args.tag]) {
      const parentTag = tagMapping[param.args.tag]
      return params.filter(p => p.type == 'property' && p.args.tag == parentTag)
    }
    return []
  }, [formTemplate, param.args.tag])

  return (
    <div className={`input-param`}>
      <ParamCombobox param={param}  pageId={pageId}  changeParamType={onParamTypeChange} />

      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label" htmlFor={param.name}>
            {translationPath.parameterLabel} :  {param.label}
          </label>
        ) : (
          <textarea
            className="custom-form-textarea"
            name={param.name}
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
        <div className="">
          {isEditing && (
            <div style={{ width: "95%" }}>
              <CustomCombobox
                label={""}
                options={tags}
                fullWidth
                value={param?.args?.tag ?? ""}
                onChange={(value: string) => {
                  param.args.tag = value
                  onParamChanged(page.id, { ...param })
                }}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
          ) || (
              <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label mt-2" htmlFor={param.args.tag}>
                {translationPath.parameterTag}: {param.args.tag || ""}
              </label>
            )
          }
          {parentTagParams.length > 0 && (
            isEditing && (
              <div style={{ width: "95%" }}>
                <CustomCombobox
                  label={""}
                  options={parentTagParams.map(p => p.name)}
                  fullWidth
                  value={param?.args?.parentParam ?? ""}
                  onChange={(value: string) => {
                    param.args.parentParam = value
                    onParamChanged(page.id, { ...param })
                  }}
                  isOpen={isParentOpen}
                  setIsOpen={setIsParentOpen}
                />
              </div>
            ) || (
              <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label mt-2" htmlFor={param.args.parentParam}>
                {translationPath.parentTag}: {param.args.parentParam || ""}
              </label>
            )
          )
          }
        </div>
      </div>
    </div>

  )
}

export default PropertyRender