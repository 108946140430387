import React, { useEffect, useState } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import Picture from "../../../assets/Picture.svg";
import {
  BeneficialCompanyEntity,
  BeneficialEntity,
  BeneficialMinorEntity,
  BeneficialPersonEntity,
} from "../../../domain/entities";
import "./addBeneficialModal.scss";
import { BeneficialClient } from "../../../services/api/BeneficialClient";
import {
  validateEmail,
  validateNumber,
} from "../../../utils/validateStringNumber";
import { getCountryByA3 } from "../../../utils/country";
import { optionsField } from "../../../utils/beneficialFields";
import { APP_ENV, config } from "../../../config";
import { dateDiffY } from "../../../utils/math";
import { validNumberInput } from "../../../domain/validators";
const lookup = require("../../../assets/lookup.png");

function AddBenificialModel({
  onClose,
  onSave,
  currentBenificialData,
  beneficialsMap,
  setBeneficialsMap,
  beneficialTypes,
  setSelectedBeneficial,
  disableLookup,
  setNewBeneficial,
  listBeneficials
}: {
  onClose: () => void;
  setSelectedBeneficial?: React.Dispatch<
    React.SetStateAction<BeneficialEntity>
  >;
  onSave?: (value: BeneficialEntity["id"]) => void;
  currentBenificialData?: BeneficialEntity;
  beneficialsMap?: Record<string, BeneficialEntity>;
  setBeneficialsMap?: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  beneficialTypes?: BeneficialEntity["type"][];
  disableLookup?: boolean,
  setNewBeneficial?: () => void
  listBeneficials?: BeneficialEntity[]

}) {
  const [file, setFile] = useState(null);
  const [existCin, setExistCin] = useState("");
  const hostName = window.location.hostname;
  const clientType = hostName.split('.')[0];
  const [typeSelected, setTypeSelected] = useState(beneficialTypes[0])
  const lookupIsDisabled = clientType == APP_ENV

  const [beneficialData, setBeneficialData] = useState<BeneficialEntity>(
    currentBenificialData?.type
      ? currentBenificialData
      : {
        type: beneficialTypes[0],
        ...config.defaultBeneficial,
      },
  );
  const [beneficials, setBeneficials] = useState<BeneficialEntity[]>([]);
  const [beneficialUnicityError, setBeneficialUnicityError] = useState("")
  const [previewSrc, setPreviewSrc] = useState(
    beneficialData?.profilePhoto?.url || String(Picture)
  );


  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownFirstName, setShowDropdownFirstName] = useState(false);
  const [showDropdownCin, setShowDropdownCin] = useState(false);

  const [filteredCompanyData, setFilteredCompanyData] = useState<
    BeneficialCompanyEntity[]
  >([]);
  const [filteredPersonData, setFilteredPersonData] = useState<
    BeneficialPersonEntity[]
  >([]);
  const { t, language } = useTranslation();

  const apiClient = useApiClientWithLoading(setLoading);
  const beneficialClient = new BeneficialClient(apiClient);
  useEffect(() => {
    beneficialClient.getAll().then(({ rows }) => {
      setBeneficials(rows);
    });
  }, []);
  const handleInputChange = (event, field, subField) => {
    let value = event.target.value;

    if (subField) {
      console.log(subField, field, value);
      setBeneficialData((prev) => ({
        ...prev,
        [subField]: {
          ...(prev[subField] || {}),
          [field]: value,
        }
      })
      );
    } else {
      console.log(field, value);
      setBeneficialData((prev) => ({ ...prev, [field]: value }));
    }
    if (field === "companyName") {
      const filteredCompanies = beneficials.filter(
        (item) =>
          item.type == typeSelected &&
          (item as BeneficialCompanyEntity)?.companyName?.toLowerCase().includes(value?.toLowerCase())
      );
      setShowDropdown(value.length > 0 && filteredCompanies?.length > 0);
      setFilteredCompanyData(filteredCompanies as BeneficialCompanyEntity[]);
    }
    if (field === "firstName") {
      const filteredPersons = beneficials.filter(
        (item) =>
          item.type == typeSelected &&
          item?.firstName?.toLowerCase().includes(value?.toLowerCase())
      );
      setShowDropdownFirstName(value.length > 0 && filteredPersons?.length > 0);
      setFilteredPersonData(filteredPersons as BeneficialPersonEntity[]);
    }
    if (field === "cin") {
      const filteredPersons = beneficials.filter(
        (item) =>
          item.type == typeSelected &&
          item?.cin?.includes(value)
      );
      setShowDropdownCin(value.length > 0 && filteredPersons?.length > 0);
      setFilteredPersonData(filteredPersons as BeneficialPersonEntity[]);
      if (lookupIsDisabled) {
        const existCin = beneficials.some((item) => item.cin === value);

        if (existCin) {
          setExistCin(t(
            "pages.editionContract.popups.benificial.validation.cinExist"
          ))
        }
        else {
          setExistCin("")
        }
      }
    }
    setErrors({});
    setBeneficialUnicityError("")
  };
  const validateCin = (cin: string | undefined) => cin && !/^\d{8}$/.test(cin);

  const submitBenificial = async (body: BeneficialEntity) => {
    try {
      setLoading(true);
      let id = beneficialData.id;
      let row = id
        ? await beneficialClient.update(id, body)
        : disableLookup ? await beneficialClient.create(body) : (await beneficialClient.upsert(body)).row;
      id = row.id;
      const newBeneficialsMap = {
        ...beneficialsMap,
        [id]: row,
      };
      if (id) {
        if (file) {
          try {
            row = await beneficialClient.uploadFile(id, "profilePhoto", file);
          } catch (error) {
            console.error("Error uploading profile photo:", error);
          }
        }
      }
      setSelectedBeneficial && setSelectedBeneficial(row);
      setBeneficialsMap && setBeneficialsMap(newBeneficialsMap);
      setNewBeneficial && setNewBeneficial()
      setLoading(false);
      toast.success(
        t("pages.editionContract.popups.benificial.messages.success")
      );
      onSave && onSave(id);
      onClose();
    } catch (error) {
      setLoading(false);
      toast.error(t("pages.editionContract.popups.benificial.messages.error"));
      console.error(error);
      onClose();
    }
  };
  const validateMinorForm = () => {
    const minorBeneficial = beneficialData as BeneficialMinorEntity;
    let formErrors = {};
    const requiredFields: (keyof BeneficialMinorEntity)[] = [
      "minorFirstName",
      "minorLastName",
      "minorOrderNumber",
      "cin",
    ];
    const fields = Object.keys(minorBeneficial) as (keyof BeneficialMinorEntity)[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !minorBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    if (minorBeneficial.cin && validateCin(minorBeneficial.cin)) {
      formErrors["cin"] = t(
        "pages.editionContract.popups.benificial.validation.invalidCin"
      );
    }
    if (minorBeneficial.email && !validateEmail(minorBeneficial.email)) {
      formErrors["email"] = t(
        "pages.editionContract.popups.benificial.validation.email"
      );
    }


    if (minorBeneficial.minorDateOfBirth && dateDiffY(new Date(), minorBeneficial.minorDateOfBirth) >= 18) {
      formErrors["minorDateOfBirth"] = t(
        "pages.editionContract.popups.benificial.validation.invalidMinorDateOfBirth"
      );
    }
    setErrors((prev) => ({
      ...prev,
      ...formErrors
    }));
    if (Object.keys(formErrors).length) {
      console.warn(formErrors);
      console.warn(beneficialData);
    }
    return Object.keys(formErrors).length === 0;
  }
  const validateSpouseForm = (spouse: BeneficialPersonEntity['spouse'], prefix = "spouse") => {
    spouse = spouse || {}
    let formErrors = {};
    const requiredFields: (keyof BeneficialPersonEntity['spouse'])[] = [
      "firstName",
      "lastName",
      "cin",
    ];
    const fields = Object.keys(spouse) as (keyof BeneficialPersonEntity['spouse'])[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[`spouse.${field}`] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !spouse[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }


    if (validateCin(spouse.cin)) {
      formErrors["spouse.cin"] = t(
        "pages.editionContract.popups.benificial.validation.invalidCin"
      );
    }

    setErrors((prev) => ({
      ...prev,
      ...formErrors
    }));
    if (Object.keys(formErrors).length) {
      console.warn(formErrors);
      console.warn(spouse);
    }
    return Object.keys(formErrors).length === 0;
  }

  const validateAttorneyForm = (attorney: BeneficialPersonEntity['attorney'], prefix: "attorney" | "spouseAttorney") => {
    attorney = attorney || {}
    let formErrors = {};
    const requiredFields: (keyof BeneficialPersonEntity['attorney'])[] = [
      "firstName",
      "lastName",
      "cin",
      "powerOfAttorneyReceitNumber",
      "powerOfAttorneyRegistrationNumber",
    ];
    const fields = Object.keys(attorney) as (keyof BeneficialPersonEntity['attorney'])[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[`${prefix}.${field}`] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !attorney[field]) {
        formErrors[`${prefix}.${field}`] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    if (validateCin(attorney.cin)) {
      formErrors[`${prefix}.${"cin"}`] = t(
        "pages.editionContract.popups.benificial.validation.invalidCin"
      );
    }
    setErrors((prev) => ({
      ...prev,
      ...formErrors
    }));
    if (Object.keys(formErrors).length) {
      console.warn(formErrors);
      console.warn(attorney);
    }
    return Object.keys(formErrors).length === 0;
  }

  const validatePersonForm = () => {
    const personBeneficial = beneficialData as BeneficialPersonEntity;
    let formErrors = {};

    const requiredFields: (keyof BeneficialPersonEntity)[] = [
      "firstName",
      "lastName",
      "cin",
    ];
    console.log(Object.keys(
      personBeneficial
    ))
    const fields = Object.keys(personBeneficial) as (keyof BeneficialPersonEntity)[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields) {
      if (requiredFields.includes(field) && !personBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
      if (field === "cin" && !validateNumber(beneficialData[field])) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.number"
        );
      }
    }
    if (personBeneficial.cin && validateCin(personBeneficial.cin)) {
      formErrors["cin"] = t(
        "pages.editionContract.popups.benificial.validation.invalidCin"
      );
    }
    if (personBeneficial.email && !validateEmail(personBeneficial.email)) {
      formErrors["email"] = t(
        "pages.editionContract.popups.benificial.validation.email"
      );
    }

    setErrors((prev) => ({
      ...prev,
      ...formErrors
    }));
    if (Object.keys(formErrors).length) {
      console.warn(formErrors);
      console.warn(beneficialData);
    }
    return Object.keys(formErrors).length === 0;
  };

  const validateCompanyForm = () => {
    const companyBeneficial = beneficialData as BeneficialCompanyEntity;
    let formErrors = {};

    const requiredFields: (keyof BeneficialCompanyEntity)[] = [
      "companyName",
      "registrationNumber"
    ];
    const fields = Object.keys(companyBeneficial) as (keyof BeneficialCompanyEntity)[]
    for (const field of requiredFields) {
      if (!fields.includes(field)) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    for (const field of fields as (keyof BeneficialCompanyEntity)[]) {
      if (requiredFields.includes(field) && !companyBeneficial[field]) {
        formErrors[field] = t(
          "pages.editionContract.popups.benificial.validation.required"
        );
      }
    }
    if (companyBeneficial.cin && validateCin(companyBeneficial.cin)) {
      formErrors["cin"] = t(
        "pages.editionContract.popups.benificial.validation.invalidCin"
      );
    }
    if (companyBeneficial.email && !validateEmail(companyBeneficial.email)) {
      formErrors["email"] = t(
        "pages.editionContract.popups.benificial.validation.email"
      );
    }
    setErrors((prev) => ({
      ...prev,
      ...formErrors
    }));
    if (Object.keys(formErrors).length) {
      console.warn(formErrors);
      console.warn(beneficialData);
    }
    return Object.keys(formErrors).length === 0;
  };
  const validateForm = () => {
    const beneficial: BeneficialEntity = beneficialData || {}

    const existBeneficial = listBeneficials?.find((b) => {
      return b.id == beneficial.id
    });
    if (existBeneficial) {
      setBeneficialUnicityError(t("pages.editionContract.popups.benificial.validation.duplicate"));
      return false;
    }

    let isValid;
    switch (beneficial.type) {
      case "Person":
        isValid = validatePersonForm()
        if (beneficial.hasAttorney)
          isValid = validateAttorneyForm(beneficial.attorney, 'attorney') && isValid
        if (beneficial.hasSpouse)
          isValid = validateSpouseForm(beneficial.spouse) && isValid
        if (beneficial.spouseAttorney)
          isValid = validateAttorneyForm(beneficial.spouseAttorney, 'spouseAttorney') && isValid
        break;
      case "Company":
        isValid = validateCompanyForm();
        if (beneficial.hasAttorney)
          isValid = validateAttorneyForm(beneficial.attorney, 'attorney') && isValid
        break;
      case "Minor":
        isValid = validateMinorForm();
        if (beneficial.hasAttorney)
          isValid = validateAttorneyForm(beneficial.attorney, 'attorney') && isValid
        break;
      default:
        return false;
    }

    if (!isValid) {
      return false;
    }

    setErrors({});
    return true;
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      setPreviewSrc(URL.createObjectURL(event.target.files[0]));
    }
  };
  const handleSave = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setBeneficialData((prevState) => {
        let beneficial = prevState
        const name =
          beneficial.type == "Person" && beneficial.firstName + " " + beneficial.lastName
          || beneficial.type == "Company" && beneficial.companyName
          || beneficial.type == "Minor" && beneficial.minorFirstName + " " + beneficial.minorLastName
        let updatedData = {
          ...prevState,
          name,
        };


        if (!updatedData.hasAttorney) {
          updatedData.attorney = null
        }
        if (updatedData.type == 'Person' && !updatedData.hasSpouse) {
          updatedData.spouse = null
          updatedData.hasSpouseAttorney = false
        }
        if (updatedData.type == 'Person' && !updatedData.hasSpouseAttorney) {
          updatedData.spouseAttorney = null
        }
        (async () => {
          await submitBenificial(updatedData);
        })();

        return updatedData;
      });
    }
  };
  const TextInputField = ({
    field,
    label,
    subField = "",
    type = "text",
  }: {
    field:
    | keyof BeneficialPersonEntity
    | keyof BeneficialCompanyEntity
    | keyof BeneficialMinorEntity
    | keyof BeneficialEntity["address"]
    | keyof BeneficialPersonEntity["spouse"]
    | keyof BeneficialPersonEntity["attorney"]
    ;
    label: string;
    subField?: '' | 'address' | 'spouse' | 'attorney' | 'spouseAttorney';
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  }) => {
    const rawValue = subField
      ? beneficialData[subField]?.[field]
      : beneficialData[field];

    const id = `${field}-${subField ?? ""}`
    return (
      <div
        className="mb-3 container-input-beneficial"
        key={id}
      >
        <label htmlFor={id} className="label-beneficial">
          {label}
        </label>

        {(field === "gender"
          || field === "minorGender"
        ) && (
            <div className="d-flex justify-content-between w-50 mt-3">
              <div>
                <input
                  name={id}
                  type="radio"
                  value="M"
                  checked={rawValue === "M"}
                  onChange={(e) => handleInputChange(e, field, subField)}
                />{" "}
                {t(`pages.editionContract.popups.benificial.gender.M`)}
              </div>
              <div>
                <input
                  name={id}
                  type="radio"
                  value="F"
                  checked={rawValue === "F"}
                  onChange={(e) => handleInputChange(e, field, subField)}
                />{" "}
                {t(`pages.editionContract.popups.benificial.gender.F`)}
              </div>
            </div>
          ) || (
            field == 'socialCapitalCurrency'
            || field == 'nationality'
            || field == 'minorNationality'
            || field == 'maritalStatus'
          ) && (
            <select
              className="form-control select-options-beneficial"
              id={id}
              value={rawValue}
              onChange={(e) => handleInputChange(e, field, subField)}
              required
            >
              {optionsField
                .find((option) => option.hasOwnProperty(field))
                ?.[field].map((option) => {
                  const country = getCountryByA3(option);
                  return field === "minorNationality" || field === "nationality" ? (
                    <option value={option} key={option}>{country[`demonym_${language}`] || country.demonym_en}</option>
                  ) : (
                    <option key={option} value={option}>
                      {t(
                        `pages.editionContract.popups.benificial.options.${field}Options.${option}`
                      )}
                    </option>
                  );
                })}
            </select>
          ) || (
            <div
              className={
                subField == '' && (field === "companyName" || field === "firstName" || field === "cin")
                  ? "input-companyName-container"
                  : "input-beneficial-container"
              }
            >
              <input
                type={field === "email" ? "email" : type}
                className="form-control custom-placeholder"
                id={field}
                value={rawValue ?? ""}
                onChange={(e) => {
                  if (type == 'number') {
                    e.target.value = validNumberInput(e.target.value) ? e.target.value
                      : validNumberInput(rawValue) ? rawValue
                        : ''
                  }
                  handleInputChange(e, field, subField)
                }
                }
              />

              {!disableLookup && !lookupIsDisabled && subField == '' && (field === "companyName" || field === "firstName" || field === "cin") && (
                <img
                  src={lookup}
                  className={`lookup-img ${language === "ar" ? "lookup-img-arabic" : ""
                    }`}
                  onClick={() => {
                    setShowDropdown(false)
                    setShowDropdownFirstName(false)
                  }}
                />
              )}

              {!disableLookup && subField == '' && field === "companyName" &&
                showDropdown &&
                filteredCompanyData &&
                !lookupIsDisabled &&
                filteredCompanyData.length > 0 && (
                  <div className="company-name-dropdown">
                    {filteredCompanyData.map((item, index) => (
                      <div
                        className="listBeneficial"
                        key={index}
                        onClick={() =>
                          handleDropdownSelect(item.companyName, field)
                        }
                      >
                        {item.companyName}
                      </div>
                    ))}
                  </div>
                )}
              {!disableLookup && subField == '' && field === "firstName" &&
                showDropdownFirstName &&
                !lookupIsDisabled &&
                filteredPersonData &&
                filteredPersonData.length > 0 && (
                  <div className="company-name-dropdown">
                    {filteredPersonData.map((item, index) => (
                      <div
                        className="listBeneficial"
                        key={index}
                        onClick={() =>
                          handleDropdownSelect(item.firstName, field)
                        }
                      >
                        <div className="d-flex justify-content-between p-1">
                          <p>
                            {item?.fullName}
                          </p>
                          <p>
                            {item?.cin}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              {!disableLookup && subField == '' && field === "cin" &&
                showDropdownCin &&
                filteredPersonData &&
                !lookupIsDisabled &&
                filteredPersonData.length > 0 && (
                  <div className="company-name-dropdown">
                    {filteredPersonData.map((item, index) => (
                      <div
                        className="listBeneficial"
                        key={index}
                        onClick={() =>
                          handleDropdownSelect(item.cin, field)
                        }
                      >
                        <p>
                          {item?.cin}
                        </p>
                      </div>

                    ))}
                  </div>
                )}
              {subField == '' && field === "cin" && lookupIsDisabled && existCin && (
                <small className="text-danger py-0 my-0">{existCin}</small>
              )}
            </div>
          )}

        {errors[subField ? `${subField}.${field}` : field] && (
          <small className="text-danger py-0 my-0">{errors[subField ? `${subField}.${field}` : field]}</small>
        )}
      </div>
    );
  };

  const handleComboBoxChange = (field) => (value) => {
    setTypeSelected(value)
    setBeneficialData({
      ...beneficialData,
      [field]: value,
    });

    setPreviewSrc(beneficialData?.profilePhoto?.url || String(Picture));
  };
  const handleDropdownSelect = (selectedValue, field) => {
    const selectedData = field === "companyName" ? filteredCompanyData.find(
      (company) => company.companyName === selectedValue
    ) : field === "firstName" ? filteredPersonData.find(
      (person) => person.firstName === selectedValue
    ) : filteredPersonData.find(
      (person) => person.cin === selectedValue
    )
    setBeneficialData({
      ...selectedData,
      [field]: selectedValue,
    });
    setShowDropdown(false);
    setShowDropdownCin(false)
    setShowDropdownFirstName(false)
  };
  useEffect(() => {
    setPreviewSrc(beneficialData?.profilePhoto?.url || String(Picture));
  }, [beneficialData]);

  const beneficialTypeMap: Record<BeneficialEntity['type'], string> = {
    'Person': 'type1',
    'Company': 'type2',
    'Minor': 'type3',
  }
  const ProfilePictureField = ({ src, alt, onChange, uniqueId }) => {
    const handleImageClick = () => {
      const fileInput = document.getElementById(`fileInput_${uniqueId}`);
      if (fileInput) {
        fileInput.click();
      }
    };
    return (
      <div className="profile-picture-container">
        <label
          htmlFor={`fileInput_${uniqueId}`}
          className="label-beneficial pb-2"
        >
          {t("pages.editionContract.popups.benificial.inputs.profilePhoto")}
        </label>
        <img
          className="beneficial-profile-img"
          src={src}
          alt={alt}
          onClick={handleImageClick}
          style={{ cursor: "pointer" }}
        />
        <input
          id={`fileInput_${uniqueId}`}
          type="file"
          className="form-control file-input"
          onChange={onChange}
          style={{ display: "none" }}
        />
      </div>
    );
  };
  const BeneficialPersonFields = () => {
    return (
      <>
        {[
          TextInputField({
            field: "firstName",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.firstname"
            ),
          }),
          TextInputField({
            field: "lastName",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.lastname"
            ),
          }),
          TextInputField({
            field: "gender",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.gender"
            ),
          }),
          TextInputField({
            field: "email",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.email"
            ),
          }),
          TextInputField({
            field: "jobTitle",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.jobTitle"
            ),
          }),
          TextInputField({
            field: "addressLine",
            subField: "address",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.address"
            ),
          }),
          TextInputField({
            field: "dateOfBirth",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.dateOfBirth"
            ),
            type: "date",
          }),
          TextInputField({
            field: "placeOfBirth",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.placeOfBirth"
            ),
          }),
          TextInputField({
            field: "cin",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.cin"
            ),
          }),
          TextInputField({
            field: "cinDeliveryDate",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.cinDeliveryDate"
            ),
            type: "date",
          }),
          TextInputField({
            field: "cinDeliverPlace",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.cinDeliverPlace"
            ),
          }),
          TextInputField({
            field: "nationality",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.nationality"
            ),
          }),

          TextInputField({
            field: "maritalStatus",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.maritalStatus"
            ),
          }),

          TextInputField({
            field: "passport",
            label: t(
              "pages.editionContract.popups.benificial.inputs.person.passport"
            ),
          }),
        ]}
      </>
    );
  };
  const BeneficialAttorneyFields = () => {
    return (<div className="col-12">
      <h5 className="mt-4 mb-3">
        {t(
          "pages.editionContract.popups.benificial.attorney"
        )}{" "}
        :
      </h5>
      <input
        type="checkbox"
        checked={beneficialData.hasAttorney}
        onChange={(e) => setBeneficialData({
          ...beneficialData,
          hasAttorney: e.target.checked
        })}
      />
      {beneficialData.hasAttorney &&
        <div className="row">
          {[
            TextInputField({
              subField: 'attorney',
              field: "firstName",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyFirstName"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "lastName",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyLastName"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "gender",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyGender"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "cin",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyCin"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "cinDeliveryDate",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliveryDate"
              ),
              type: "date",
            }),
            TextInputField({
              subField: 'attorney',
              field: "cinDeliverPlace",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliverPlace"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "regionalFinancialOffice",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.regionalFinancialOffice"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "powerOfAttorneyDate",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyDate"
              ),
              type: "date",
            }),
            TextInputField({
              subField: 'attorney',
              field: "powerOfAttorneyReceitNumber",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyReceitNumber"
              ),
            }),
            TextInputField({
              subField: 'attorney',
              field: "powerOfAttorneyRegistrationNumber",
              label: t(
                "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyRegistrationNumber"
              ),
            }),
          ]}
        </div>
      }
    </div>)
  }
  return (
    <>
      <>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal beneficial-modal-component  d-flex justify-content-center align-items-center">
            <div>
              <div className="modal-content content-beneficial">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {currentBenificialData?.type ? t("pages.beneficials.titleEdit") : t("pages.editionContract.popups.benificial.title")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={onClose}
                    aria-label="Close"
                    style={{ marginRight: language == "ar" && "88%" }}
                  ></button>
                </div>
                {loading ? (
                  <Loading height="50vh" />
                ) : (
                  <div className="modal-body body-beneficial">
                    <div
                      className="form-group"
                      style={{ marginTop: "-2%" }}
                      onSubmit={handleSave}
                    >
                      <div className="form-floating mb-3">
                        <div className="slider-wrapper">
                          {beneficialTypes.map((type, idx) => (
                            <div
                              className={`slider-option 
                                ${((beneficialTypes.length > 1) && idx == 0 && (language == "ar" ? "right" : "left")) || ""}
                                ${((beneficialTypes.length > 1) && idx == (beneficialTypes.length - 1) && (language == "ar" ? "left" : "right")) || ""}
                                ${beneficialData.type === type ? "selected-type-beneficial" : ""}
                                  `}
                              onClick={() => {
                                setBeneficialUnicityError("")
                                handleComboBoxChange("type")(type)
                              }
                              }
                            >
                              {t(
                                "pages.editionContract.popups.benificial." + beneficialTypeMap[type]
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="content-input-form">
                        {beneficialData.type === "Person" &&
                          <>
                            {BeneficialPersonFields()}
                            {BeneficialAttorneyFields()}
                            <div className="col-12">
                              <h5 className="mt-4 mb-3">
                                {t(
                                  "pages.editionContract.popups.benificial.spouse"
                                )}{" "}
                                :
                              </h5>
                              <input
                                type="checkbox"
                                checked={beneficialData.hasSpouse}
                                onChange={(e) => setBeneficialData({
                                  ...beneficialData,
                                  type: 'Person',
                                  hasSpouse: e.target.checked
                                })}
                              />
                              {beneficialData.hasSpouse &&
                                <div className="row">
                                  {[
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "firstName",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseFirstName"
                                      ),
                                    }),
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "lastName",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseLastName"
                                      ),
                                    }),
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "gender",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseGender"
                                      ),
                                    }),
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "cin",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseCin"
                                      ),
                                    }),
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "cinDeliveryDate",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseCinDeliveryDate"
                                      ),
                                      type: "date",
                                    }),
                                    TextInputField({
                                      subField: 'spouse',
                                      field: "cinDeliverPlace",
                                      label: t(
                                        "pages.editionContract.popups.benificial.inputs.joint.spouseCinDeliverPlace"
                                      ),
                                    }),
                                  ]}
                                </div>
                              }
                            </div>
                            {
                              beneficialData.hasSpouse && (
                                <div className="col-12">
                                  <h5 className="mt-4 mb-3">
                                    {`${t("pages.editionContract.popups.benificial.attorney")} (${t("pages.editionContract.popups.benificial.spouse")
                                      }):`}
                                  </h5>
                                  <input
                                    type="checkbox"
                                    checked={beneficialData.hasSpouseAttorney}
                                    onChange={(e) => setBeneficialData({
                                      ...beneficialData,
                                      type: 'Person',
                                      hasSpouseAttorney: e.target.checked
                                    })}
                                  />
                                  {beneficialData.hasSpouseAttorney &&
                                    <div className="row">
                                      {[
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "firstName",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyFirstName"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "lastName",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyLastName"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "gender",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyGender"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "cin",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyCin"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "cinDeliveryDate",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliveryDate"
                                          ),
                                          type: "date",
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "cinDeliverPlace",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliverPlace"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "regionalFinancialOffice",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.regionalFinancialOffice"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "powerOfAttorneyDate",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyDate"
                                          ),
                                          type: "date",
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "powerOfAttorneyReceitNumber",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyReceitNumber"
                                          ),
                                        }),
                                        TextInputField({
                                          subField: 'spouseAttorney',
                                          field: "powerOfAttorneyRegistrationNumber",
                                          label: t(
                                            "pages.editionContract.popups.benificial.inputs.attorney.powerOfAttorneyRegistrationNumber"
                                          ),
                                        }),
                                      ]}
                                    </div>
                                  }
                                </div>
                              )
                            }
                          </>

                        }
                        {beneficialData.type == "Company" && (
                          <>
                            {[
                              TextInputField({
                                field: "companyName",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.companyName"
                                ),
                              }),
                              TextInputField({
                                field: "postalAddress",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.addressPostal"
                                ),
                              }),
                              TextInputField({
                                field: "socialCapital",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.socialCapital"
                                ),
                                type: "number",
                              }),
                              TextInputField({
                                field: "legalForm",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.legalForm"
                                ),
                              }),
                              TextInputField({
                                field: "registrationNumber",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.registrationNumber"
                                ),
                              }),
                              TextInputField({
                                field: "specialization",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.specialization"
                                ),
                              }),
                              TextInputField({
                                field: "socialCapitalCurrency",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.company.socialCapitalCurrency"
                                ),
                              }),
                              ProfilePictureField({
                                uniqueId: "company",
                                src: previewSrc,
                                alt: "Profile",
                                onChange: handleFileChange,
                              }),
                            ]}
                            <div className="col-12">
                              <h5 className="mt-4 mb-3">
                                {t(
                                  "pages.editionContract.popups.benificial.responsableSociete"
                                )}{" "}
                                :
                              </h5>
                              <div className="row">
                                {BeneficialPersonFields()}
                                {BeneficialAttorneyFields()}
                              </div>
                            </div>
                          </>
                        )}
                        {beneficialData.type == "Minor" && (
                          <>
                            {[
                              TextInputField({
                                field: "minorFirstName",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorFirstName"
                                ),
                              }),
                              TextInputField({
                                field: "minorLastName",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorLastName"
                                ),
                              }),
                              TextInputField({
                                field: "minorGender",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorGender"
                                ),
                              }),
                              TextInputField({
                                field: "minorDateOfBirth",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorDateOfBirth"
                                ),
                                type: "date",
                              }),
                              TextInputField({
                                field: "minorPlaceOfBirth",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorPlaceOfBirth"
                                ),
                              }),
                              TextInputField({
                                field: "minorNationality",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorNationality"
                                ),
                              }),
                              TextInputField({
                                field: "minorOrderNumber",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorOrderNumber"
                                ),
                              }),
                              TextInputField({
                                field: "minorOrderDate",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorOrderDate"
                                ),
                                type: "date",
                              }),
                              TextInputField({
                                field: "minorOrderCourt",
                                label: t(
                                  "pages.editionContract.popups.benificial.inputs.minor.minorOrderCourt"
                                ),
                              }),
                            ]}
                            <div className="col-12">
                              <h5 className="mt-4 mb-3">
                                {t(
                                  "pages.editionContract.popups.benificial.tutor"
                                )}{" "}
                                :
                              </h5>
                              <div className="row">
                                {BeneficialPersonFields()}
                                {BeneficialAttorneyFields()}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {beneficialUnicityError && <small className="text-danger py-0 my-0">{beneficialUnicityError}</small>}
                      <div
                        className=""
                        style={{ border: "none", marginTop: "1%" }}
                      >
                        {
                          <button
                            type="submit"
                            className="btn btn-primary add-beneficial-btn"
                            onClick={handleSave}
                          >
                            {t("pages.editionContract.popups.benificial.save")}
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default AddBenificialModel;
