import React, { useContext, useEffect, useMemo } from "react";
import "../commonmodal.scss";


import { useTranslation } from '../../../contexts/TranslationProvider';
import { ContractTemplateEntity } from "../../../domain/entities";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
interface ClauseSettingsModalPropos {
	onClose: () => void;
}

function ClauseSettingsModal({
	onClose,
}: ClauseSettingsModalPropos
) {

	const { contractTemplate, setContractTemplate } = useContext(EditContractTemplateContext);

	const { t, language } = useTranslation();

	useEffect(() => {
		if (!contractTemplate.clauseSettings?.[0]) {
			setContractTemplate((prev) => ({
				...prev,
				clauseSettings: [{
					range: [1, prev.clauses?.length ?? 1],
					clauseFormula: "$clauseName",
					subClauseFormula: "$subClauseName",
				}],
			}))
		}
		return () => {
		}
	}, [])

	let clauseSetting = useMemo(() => contractTemplate.clauseSettings?.[0] ?? {
		range: [1, contractTemplate.clauses?.length ?? 1],
		clauseFormula: "$clauseName",
		subClauseFormula: "$subClauseName",
	}, [contractTemplate])


	const updateClauseSettings = (field: keyof ContractTemplateEntity['clauseSettings'][number], value: any) => {
		console.log("updateClauseSettings", field, value);
		clauseSetting[field] = value
		setContractTemplate((prev) => ({
			...prev,
			clauseSettings: [clauseSetting] as any,
		}))
	}

	return (
		<>
			<div
				className="modal-backdrop"
			></div>
			<div id="contractz-lab">
				<div className="modal d-flex justify-content-center align-items-center">
					<div>
						<div className="modal-content" style={{ width: "960px" }}>
							<div className="modal-header">
								<h5 className="modal-title">  {"Clause Settings"}</h5>
								<button
									type="button"
									className="btn-close"
									onClick={onClose}
									aria-label="Close"
								// style={{ marginRight: language == "ar" && "88%" }}
								></button>
							</div>
							<div className="modal-body" style={{ paddingBottom: "5%" }}>
								<div className="form-group validate">
									{/* <div className="form-floating mb-3">
										<div>
											<label >Range</label>
											<input className="range" type="number" min={1} max={Math.min(contractTemplate.clauses.length, clauseSetting.range[1])} value={clauseSetting.range[0]} onChange={(e) => updateClauseSettings('range', [e.target.value, clauseSetting.range[1]])}></input>
											<input className="range" type="number" min={Math.max(0, clauseSetting.range[0])} max={contractTemplate.clauses.length} value={clauseSetting.range[1]} onChange={(e) => updateClauseSettings('range', [clauseSetting.range[0], e.target.value])}></input>
										</div>
									</div> */}
									<div className="form-floating mb-3">
										<div>
											<label>sub clause name</label>
											<input type="text" value={clauseSetting.subClauseFormula} onChange={(e) => updateClauseSettings('subClauseFormula', e.target.value)}></input>
										</div>
									</div>
									<div className="form-floating mb-3">
										<div>
											<label>clause name</label>
											<input type="text" value={clauseSetting.clauseFormula} onChange={(e) => updateClauseSettings('clauseFormula', e.target.value)}></input>
										</div>
									</div>

								</div>
							</div>

							{/* <div className="modal-footer">
								<button
									type="submit"
									className="btn btn-primary submit-validate-contract-btn"
									onClick={() => {
										onSave(entity)
										onClose()
									}}
								>
									{translationPath.select}
								</button>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


export default ClauseSettingsModal;
