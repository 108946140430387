import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { sortByOriginalOrder } from '../../../../utils/array';
import { BeneficialEntity, beneficialTypes } from '../../../../domain/entities';
import { lowerCase } from 'lodash';

function BeneficialListRender({ param, page, isEditing, value, valueName, onChange, onChangeName, errors, onDescriptionChange }) {
  const { onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  type BeneficialType = 'Person' | 'Company' | 'Minor' | 'Joint' | 'Attorney';
  const updatedParam = { ...param };

  if (!updatedParam.args?.beneficialTypes || updatedParam.args.beneficialTypes.length === 0) {
    updatedParam.args = {
      ...updatedParam.args,
      beneficialTypes,
    };
    onParamChanged(page.id, updatedParam);
  }
  const handleCheckboxChange = (checked: boolean, type: BeneficialType) => {
    const updatedParam = { ...param };
    if (!updatedParam.args) {
      updatedParam.args = { beneficialTypes: [] };
    }

    if (checked) {
      updatedParam.args.beneficialTypes = sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
    } else {
      updatedParam.args.beneficialTypes = updatedParam.args.beneficialTypes.filter((i: BeneficialType) => i !== type);
    }

    onParamChanged(page.id, updatedParam);
  };

  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterLabel} :{param.label}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>

      {beneficialTypes.map((type) => (
        <div className="checkbox-beneficial">
          <input
            type="checkbox"
            checked={param?.args?.beneficialTypes?.includes(type) || false}
            name={type}
            onChange={(e) => handleCheckboxChange(e.target.checked, type)}
            disabled={false}
          />
          <label htmlFor={type}>{translationPath.beneficial[lowerCase(type)]}</label>
        </div>
      ))}
    </div>
  )
}

export default BeneficialListRender;
