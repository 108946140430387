import { FormParam } from "./types/FormParams";

import { math } from "../utils/math"

import { RenderSegment, SegmentedClauseParam, SegmentedTextType } from "./types/ClauseParams";
import { ParagraphProps } from "./entities/ParagraphProps";
import { FormEntity, FormTemplateEntity, FormTemplatePageEntity } from "./entities";
import { transpose } from "../utils/array";
import { escapeXml } from "./Contract";
import { cloneDeep } from "lodash";
import { getAllRenderedParams } from "./FormTemplate";
import moment from "moment";
export type CreateFormFromDocRequest = {
	file: Blob & { name: string };
	name: string;
	templateId: number;
}

export function getRenderedFormTemplate(form: FormEntity): FormTemplateEntity {
	let formTemplate = cloneDeep(form.template)
	formTemplate.pages.forEach((page) => {
		let childrenParamMap: Record<FormParam['name'], FormParam[]> = {}
		let existingParams = {}
		let params = getAllRenderedParams(page.params, form.paramValues)
		let mainParams: FormParam[] = []
		for (const param of params) {
			if (!param.condition?.[0]?.[0]) {
				mainParams.push(param)
			}
		}
		let rendredParams: FormParam[] = []
		for (const param of params) {
			if (param.condition?.[0]?.[0]) {
				const parentParamName = param.condition?.[0]?.[0].name
				childrenParamMap[parentParamName] = childrenParamMap[parentParamName] || []
				childrenParamMap[parentParamName].push(param)
			}
		}
		for (const param of mainParams) {
			if (!existingParams[param.name]) {
				rendredParams.push(param)
				existingParams[param.name] = true
				for (const childParam of childrenParamMap[param.name] || []) {
					if (!existingParams[childParam.name]) {
						rendredParams.push(childParam)
						existingParams[childParam.name] = true
					}
				}
			}
		}
		console.log(rendredParams);

		page.params = rendredParams
	})
	return formTemplate
}

export function validateFormula(formula: string, params: (FormParam | SegmentedClauseParam)[]): boolean {

	try {
		const data = {}

		params.forEach(p => {
			switch (p.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					data[p.name] = 0
					break;
				case 'date':
					data[p.name] = new Date().getTime()
					break;
				default:
					break;
			}
		})

		const compiledFormula = math.parse(formula);

		// Evaluate the formula with the given data as variables
		compiledFormula.evaluate(data);

		return true; // All symbols are valid
	} catch (error) {
		console.error('Error parsing formula:', error);
		return false; // Formula is invalid if there's an error in parsing
	}
}
export function validateFormat(format, params) {
  try {
    const data = {};
    params.forEach(p => {
      switch (p.type) {
        case 'boolean':
          data[p.name] = false;
          break;
        case 'enum':
          data[p.name] = 0; 
          break;
        case 'string':
          data[p.name] = '';
          break;
        case 'number':
          data[p.name] = 0;
          break;
        case 'date':
          data[p.name] = new Date().getTime();
          break;
        default:
          break;
      }
    });
    const allowedFormats = new Set([
      'numberToLetters',
      'formatWithSpaces',
      'formatDecimal',
      'formatPercentage',
      'formatThousands',
      'formatCurrencyUSD',
      'formatCurrencyEUR',
      'formatCurrencyTND',
      'formatScientific',
      'fmtShortMMDDYYYY',
      'fmtShortDDMMYYYY',
      'fmtISO',
      'fmtLongMonthDayYear',
      'fmtLongDayMonthYear',
      'fmtWeekdayMonthDayYear',
      'fmtWithTime24',
      'fmtWithTimeFull24',
      'fmtWithTimeHMSS',
      'fmtWithTime12',
      'fmtWithTimeFull12',
      'fmtMonthDayYear12',
      'fmtWeekdayShort',
      'fmtWeekdayLong',
      'fmtWeekdayMonthDay',
      'fmtISOExtended',
      'fmtRFC'
    ]);

    if (typeof format === 'string' && allowedFormats.has(format)) {
      return true;
    }
    const compiledFormat = math.parse(format);
    compiledFormat.evaluate(data);

    return true; 
  } catch (error) {
    console.error('Error parsing format:', error);
    return false;
  }
}


export function validateConstraint(constraint: string, params: (FormParam | SegmentedClauseParam)[]): boolean {
	try {
		const data: { [key: string]: any } = {};
		params.forEach(p => {
			switch (p.type) {
				case 'boolean':
				case 'enum':
				case 'number':
					// Set mock number/boolean/enum values
					data[p.name] = 0;
					break;
				case 'date':
					// Set mock date value
					data[p.name] = new Date().getTime()
					break;
				case 'string':
					data[p.name] = ''
					break;
				default:
					break;
			}
		});
		// Parse the constraint to check if it's a valid expression
		const compiledConstraint = math.parse(constraint);

		// Try to evaluate it with mock data to ensure it can be executed
		compiledConstraint.evaluate(data);

		// If no error was thrown, the constraint is valid
		return true;
	} catch (error) {
		console.error('Error parsing constraint:', error);
		return false; // Return false if the constraint is invalid
	}
}

export function evaluateParamConstraints(param: FormParam | SegmentedClauseParam, data: { [key: string]: number | string | Date }, params: (FormParam | SegmentedClauseParam)[]): [string, boolean][] | null {
	if (!((param.type == 'number' || param.type == 'string' || param.type == 'date') && param.constraints)) {
		return null; // Return null if there's no formula
	}
	let evalData = {
		...data
	}
	params.forEach((param) => {
		const key = param.name
		if (!evalData[key]) {
			switch (param.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					evalData[key] = 0
					break;
				case 'date':
					evalData[key] = new Date().getTime()
					break;
				default:
					break;
			}
			return
		}
		if (param.type == 'boolean')
			evalData[key] = evalData[key] ? 1 : 0
		if (param.type == 'number')
			evalData[key] = parseFloat(evalData[key]?.toString()) ?? 0
		if (param.type == 'date')
			evalData[key] = new Date(evalData[key]).getTime()
	})

	const results: [string, boolean][] = param.constraints.map(constraint => {
		try {
			// Replace parameter names with values in the formula
			const compiledConstraint = math.parse(constraint.value);

			// Evaluate the formula with the given data as variables
			const result = compiledConstraint.evaluate(evalData);

			return [constraint.label, result];
		} catch (error) {
			console.error('Error evaluating constraint:', error);
			return [constraint.label, false];
		}
	});
	return results;
}

export function evaluateParamFormula(param: (FormParam | SegmentedClauseParam), data: { [key: string]: number | string | Date }, params: (FormParam | SegmentedClauseParam)[]): number | string | Date | null {
	if (!((param.type == 'number' || param.type == 'string' || param.type == 'date') && param.formula)) {
		return null; // Return null if there's no formula
	}
	let evalData = {
		...data
	}
	params.forEach((param) => {
		const key = param.name
		if (!param) return
		if (!evalData[key]) {
			switch (param.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					evalData[key] = 0
					break;
				case 'date':
					evalData[key] = new Date().getTime()
					break;
				default:
					break;
			}
			return
		}
		if (param.type == 'boolean')
			evalData[key] = evalData[key] ? 1 : 0
		if (param.type == 'number')
			evalData[key] = parseFloat(evalData[key]?.toString()) ?? 0
		if (param.type == 'date')
			evalData[key] = new Date(evalData[key]).getTime()
	})

	delete evalData[param.name]
	try {
		// Replace parameter names with values in the formula
		const compiledFormula = math.parse(param.formula);
		// Evaluate the formula with the given data as variables

		const result = compiledFormula.evaluate(evalData);

		if (param.type == 'number')
			return Number(result)
		if (param.type == 'date')
			return new Date(result)
		return result;
	} catch (error) {
		console.error('Error evaluating formula:', param.formula);
		console.error(error);
		console.warn({
			param,
			data
		});
		return Number.NaN;
	}
}
export function evaluateParamFormat(param: (FormParam | SegmentedClauseParam), currentValue: any): number | string | Date | null {
	if (!((param.type === 'number' || param.type === 'date') && param.format)) {
			return null; 
	}

	if (param.type === 'number' && isNaN(currentValue)) {
			currentValue = 0; 
	} else if (param.type === 'date' && !(currentValue instanceof Date) && isNaN(new Date(currentValue).getTime())) {
				currentValue = Date.now();
			}
	try {
			const compiledFormat = math.parse(param.format);
			const result = compiledFormat.evaluate({ [param.name]: currentValue });

			 return result;
	} catch (error) {
			console.error('Error evaluating formula:', param.format);
			console.error(error);
			console.warn({ param, currentValue });
			return Number.NaN;
	}
}



export type FormDocxExportText = {
	values?: any;
	name: string;
	pages: ({
		name: string;
		rows: {
			label: string;
			value: string;
		}[]
	})[];
	tables: {
		name: string;
		xmlText: string;
	}[];
	beneficials: {
		name: string;
		rows: {
			label: string;
			value: string;
		}[]
	}[];
}

export function renderTableToDocXml(segment: RenderSegment, form: FormEntity, paragraphProp?: ParagraphProps, alignment: string = "left") {
	const { id, value, type, style } = segment;
	let fontFamily = paragraphProp?.font?.family ?? "Arial";
	const fontSize = String(Number(paragraphProp?.font?.size) || 20);
	let fontColor = paragraphProp?.font?.color ?? "000000";
	switch (type) {
		case SegmentedTextType.PARAM_TABLE_VALUE:
			let [transposed, tableRows] = JSON.parse(value) as [boolean, string[][]]
			if (transposed) {
				tableRows = transpose(tableRows)
			}
			return `
			<w:tbl>
			<w:tblPr>
			    <w:jc w:val="center"/>
			    <w:tblW w:w="${10000}" w:type="dxa" />
					<w:tblStyle w:val="TableGrid" />
					<w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1" />
			</w:tblPr>
			<w:tblGrid>
					${'<w:gridCol w:w="4601" />'.repeat(tableRows[0]?.length ?? 1)}
			</w:tblGrid>
			${tableRows.map((row) =>
				`<w:tr w:rsidR="007667F4" w14:paraId="4F5EFE64" w14:textId="77777777" w:rsidTr="007667F4">
							${row.map((cell) =>
					`<w:tc>
											<w:tcPr>
													<w:tcW w:w="4601" w:type="dxa" />
													<w:tcBorders>
															<w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
													</w:tcBorders>
											</w:tcPr>
											<w:p w14:paraId="27D15200" w14:textId="7A92AB9E" w:rsidR="007667F4" w:rsidRDefault="007667F4" w:rsidP="005517FD">
													<w:pPr>
															<w:rPr>
																	<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
																	<w:sz w:val="${fontSize}"/>
																	<w:color w:val="${fontColor}"/>
															</w:rPr>
													</w:pPr>
													<w:r>
															<w:rPr>
																	<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
																	<w:sz w:val="${fontSize}"/>
																	<w:color w:val="${fontColor}"/>
															</w:rPr>
															<w:t>${escapeXml(cell)}</w:t>
													</w:r>
											</w:p>
									</w:tc>`).join("")}
					</w:tr>`).join("")}
	</w:tbl>	
`
		case SegmentedTextType.PARAM_COMMENT_VALUE:
			return ""
		default:
			return ""
	}
}

export function closeDocxmlText(docXml: string, paragraphProp?: ParagraphProps) {
	let alignment = "left";
	const indentation = paragraphProp?.indentation ?? { left: "0", right: "0" };
	const spacing = paragraphProp?.spacing ?? { before: "0", after: "0" };
	const fontFamily = paragraphProp?.font?.family ?? "Arial";
	const fontSize = String(Number(paragraphProp?.font?.size) || 24);
	const fontColor = paragraphProp?.font?.color ?? "000000";
	docXml += `<w:p>
	<w:pPr>
			<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" />
			<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
			${alignment && `<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />`}
	</w:pPr>
	<w:r>
	<w:rPr>
			<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
			<w:sz w:val="${fontSize}"/>
			<w:color w:val="${fontColor}"/>
	</w:rPr>
	<w:t>`
	return docXml.replaceAll(/>\n\s*</g, "><")
		.replaceAll('\n', '<w:br/>')
}